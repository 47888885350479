<!--
 * @Description: 代理商
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-23 17:08:18
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/login/agent.vue
-->
<template>
  <div class="login-view">
    <div class="login-image">
      <img src="" alt="" />
    </div>
    <div class="login-right">
      <!-- 代理商 -->
      <div class="login-form">
        <div class="form-title">成为代理商</div>
        <div class="form-text">请填写正确信息</div>
        <div class="form-input">
          <input type="text" v-model="form.name" placeholder="请输入您的姓名" />
        </div>
        <div class="form-input">
          <input
            type="text"
            v-model="form.mobile"
            placeholder="请输入您的联系方式"
          />
        </div>
        <div class="form-input">
          <input
            type="text"
            v-model="form.company"
            placeholder="请输入您的公司名称"
          />
        </div>
        <div class="form-input">
          <input
            type="text"
            v-model="form.area"
            placeholder="您的申请区域(请以省份为单位进行申请)"
          />
        </div>
        <div class="form-textarea">
          <textarea
            type="textarea"
            v-model="form.consult"
            placeholder="请简单描述您为什么适合做代理，以及需要咨询的问题"
          ></textarea>
        </div>

        <div
          :class="['form-submit', { 'form-submit-none': !isDisplay }]"
          @click="isDisplay ? handleSubmit() : null"
        >
          提交
        </div>
        <!-- <div class="form-forgot-password" @click="handleLogin()">前往登录</div> -->
      </div>
    </div>

    <div class="login-logo" @click="goPages('Index')">
      <img src="../../../assets/index/logo.png" />
    </div>

    <!-- 失败 -->
    <div class="negative-popup" v-if="errorState" @click="errorState = false">
      <div class="negative" @click.stop>
        <div class="negative-icon">
          <img src="../../../assets/icon/error.png" alt="" />
        </div>
        <div class="negative-text">请联系（满筹）处理</div>
        <div class="negative-close" @click="errorState = false">
          <img src="../../../assets/icon/close.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "../../../utils/request";

const URL = {
  agentLeaveWorld: "api/operation/agentLeaveWorld",
};

export default {
  data() {
    return {
      errorState: false,
      form: {
        name: "",
        mobile: "",
        company: "",
        area: "",
        consult: "",
      },
    };
  },
  computed: {
    isDisplay() {
      return (
        !!this.form.name &&
        !!this.form.mobile &&
        !!this.form.company &&
        !!this.form.area &&
        !!this.form.consult
      );
    },
  },
  mounted() {},
  methods: {
    goPages(name) {
      this.$router.push({
        name,
      });
    },
    async handleSubmit() {
      if (!/^1[3456789]\d{9}$/.test(this.form.mobile)) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      await HTTP({
        url: URL.agentLeaveWorld,
        data: {
          ...this.form,
        },
      });
      // this.errorState = true;
      this.$message.success("提交成功");
    },
    handleLogin() {
      this.$router.push({
        name: "Login",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-view {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  display: flex;
  align-items: center;
  .login-image {
    width: 50%;
    height: 100%;
    background-image: url("https://aboostify.oss-cn-hangzhou.aliyuncs.com/workstation/system/image/common_background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
  }
  .login-right {
    width: 50%;
  }
  .login-form {
    width: 418px;
    margin: 0 auto;
    .form-title {
      font-size: 36px;
      font-family: Alibaba PuHuiTi-Bold, Alibaba PuHuiTi;
      font-weight: bold;
      color: #000000;
      line-height: 50px;
    }
    .form-text {
      font-size: 20px;
      font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
      font-weight: 400;
      color: #999999;
      line-height: 30px;
      margin-top: 6px;
      margin-bottom: 40px;
    }
    .form-input {
      height: 52px;
      border: 1px solid #d9e0ed;
      border-radius: 4px;
      background-color: #f6f9ff;
      margin-top: 15px;
      display: flex;
      align-items: center;
      input {
        flex: 1;
        height: 100%;
        border: none;
        border-radius: 4px;
        padding: 0 10px;
        font-size: 15px;
        background-color: #f8faff;
      }
      .form-code {
        font-size: 16px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #ee8031;
        margin: 0 10px;
        cursor: pointer;
      }
    }
    .form-textarea {
      height: 125px;
      border: 1px solid #d9e0ed;
      border-radius: 4px;
      background-color: #f6f9ff;
      margin-top: 15px;
      textarea {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 4px;
        font-size: 15px;
        background-color: #f6f9ff;
        padding: 10px;
      }
    }
    .form-submit {
      width: 418px;
      height: 52px;
      background: linear-gradient(136deg, #ffa250 0%, #ff7800 100%);
      border-radius: 8px 8px 8px 8px;
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
      font-weight: normal;
      color: #ffffff;
      cursor: pointer;
    }
    .form-submit-none {
      opacity: 0.7;
      cursor: no-drop;
    }
    .form-forgot-password {
      width: 418px;
      text-align: center;
      font-size: 16px;
      font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
      font-weight: 400;
      color: #999999;
      line-height: 30px;
      margin-top: 14px;
      cursor: pointer;
    }
  }
}

.negative-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  .negative {
    width: 484px;
    height: 345px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    &-icon {
      width: 88px;
      height: 88px;
      margin: auto {
        top: 92px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    &-text {
      font-size: 28px;
      font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
      font-weight: normal;
      color: #000000;
      line-height: 30px;
      text-align: center;
      margin-top: 42px;
    }
    &-close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.login-logo {
  width: 133px;
  height: 38px;
  overflow: hidden;
  position: fixed;
  top: 20px;
  left: calc(50% + 20px);
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
